import 'react-responsive-carousel/lib/styles/carousel.min.css'
// import {media} from 'styled-bootstrap-grid'
// import styled from 'styled-components'
import Features from '../../components/revenue/Features'
import Wellness from '../../components/wellness/Wellness'
import IndustryStandard from '../../components/industryStandard/IndustryStandard'
// import Research from '../../components/research/Research'
// import CommunitySection from '../../components/community/CommunitySection'
import Form from '../../components/form/Form'

const Home = () => {
	return (
		<div>
			<IndustryStandard />
			<Features />
			<Form />
		</div>
	)
}

// const PremimumBox = styled.div`
// 	padding: 2rem;
// 	background: #f8f9fa;
// 	box-shadow: none;
// 	border-radius: 10px;
// 	height: auto;
// 	${media.md`
// 	position: absolute;
// 	top:5%;
// 	left: 4%;
// 	// z-index: 2;
// 	width: 400px;
// 	box-shadow: 0 2px 10px rgba(0,0,0,.1);
// 	`}
// `

// const Heading = styled.h2`
// 	font-weight: 500;
// 	text-align: center;
// 	${media.md`text-align: left;`}
// `
// const PremimumText = styled.p`
// 	font-size: 23px;
// 	margin-top: 1rem;
// 	text-align: center;
// 	${media.md`text-align: left;`}
// `

// const PremiumGridBox = styled.div`
// 	display: grid;
// 	grid-template-columns: repeat(2, 1fr);
// 	gap: 1rem;
// 	paddint: 2rem;
// `
// const GridItem = styled.div`
// 	padding: 1rem 0.2rem;
// 	text-align: center;
// 	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
// 	border-radius: 10px;
// `
// const ItemHeading = styled.h3`
// 	font-size: 19px;
// 	font-weight: 500;
// 	margin-top: 0.7rem;
// `

export default Home
