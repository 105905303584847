import React from 'react'
import Why from '../../components/why/Why'

const Business = () => {

    return (
        <div>
            <Why />
        </div>
    )
}

export default Business