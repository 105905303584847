import React from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'

const Wellness = () => {
	return (
		<WellnessWraper>
			<Heading>Versatile Booking Solution for Businesses of All Shapes and Sizes</Heading>

			<Paragraph>Custom Software Solutions Tailored for your business</Paragraph>

			<GridBox>
				<GridItem>
					<img src="./images/Premium/fitness.webp" alt="fitness" height="108px" />
					<ItemHeading>Fitness</ItemHeading>
				</GridItem>
				<GridItem>
					<img src="./images/Premium/salon.webp" alt="salon" height="108px" />
					<ItemHeading>Salon</ItemHeading>
				</GridItem>

				<GridItem>
					<img src="./images/Premium/spa.webp" alt="spa" height="108px" />
					<ItemHeading>Spa</ItemHeading>
				</GridItem>

				{/* <GridItem>
					<img src="./images/Premium/health.webp" alt="health" height="108px" />
					<ItemHeading>
						Integrative <br /> Health
					</ItemHeading>
				</GridItem> */}
				<GridItem>
					<img src="./images/Premium/health.webp" alt="health" height="108px" />
					<ItemHeading>
						Doctors <br /> Hospital <br /> Clinics
					</ItemHeading>
				</GridItem>
			</GridBox>
		</WellnessWraper>
	)
}

const WellnessWraper = styled.div`
	height: auto;
	background: #f8f9fa;
	margin-top: 5rem;
	padding: 5rem;
`

const Heading = styled.h2`
	text-align: center;
	font-weight: bold;
	font-size: 30px;

	${media.md`font-size: 40px;`}
`
const Paragraph = styled.p`
	color: #3b3f45;
	font-size: 20px;
	font-weight: 600;
	margin-top: 0.2rem;
	text-align: center;
`
const GridBox = styled(Container)`
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(4, 1fr);

	@media (max-width: 1026px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 500px) {
		grid-template-columns: repeat(1, 1fr);
	}
`

const GridItem = styled.div`
	padding: 1rem 0.2rem;
	text-align: center;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
`
const ItemHeading = styled.h3`
	font-size: 22px;
	font-weight: bold;
	margin-top: 0.7rem;
`

export default Wellness
