import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Container, media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

const Navbar = () => {
    const _ref = useRef(null);
    const [openDrop, setOpenDrop] = useState(false);

    function scrollToSection(sectionId) {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    useEffect(() => {
        function handleClickOutside(event) {
            if (_ref.current && !_ref.current.contains(event.target)) {
                setOpenDrop(false);
            }
        }

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return (
        <Header>
            <Container fluid >
                <Nav>
                    <Link to={"/"}>
                        <Flex>
                            <LogoWrapper> <Logo src="./images/logo.png"></Logo></LogoWrapper>
                            <Text>eBookingSystem</Text>
                        </Flex>
                    </Link>
                    <AuthWrapper>
                        <Link target="blank" to="https://ebookingsystem.com/login"><Button style={{ marginRight: "1rem" }}>Login</Button></Link>
                        <Link to={"#contactus"} onClick={() => scrollToSection("contactus")}><Button>Contact Us</Button></Link>
                    </AuthWrapper>
                    <ResponsiveBar ref={_ref} onClick={() => { setOpenDrop(!openDrop) }} >
                        <Bar width='30' src="/images/icons/bars.svg" alt="bars" />
                        <DropDown active={openDrop}>
                            <DropList target="blank" to="https://ebookingsystem.com/login">Login</DropList>
                            <DropList to={"#contactus"} onClick={() => scrollToSection("contactus")}>Contact Us</DropList>
                        </DropDown>
                    </ResponsiveBar>
                </Nav>
            </Container>
        </Header>
    )
}

const Header = styled.header`
    border-bottom: 1px solid lightgray;
    padding: 0.5rem 0rem;
    position: sticky;
    top:0;
    z-index: 5;
    background: white;
`
const Nav = styled.div`
    display: flex;
    justify-content:space-between;
    align-items:center;
    padding: 0rem;
${media.lg`padding: 0 3rem;`}
`

const Logo = styled.img`
    cursor: pointer;
    height: 4rem;
`

const Button = styled.button`
    background: rgb(9, 44, 76);
    text-transform: uppercase;
    padding: 0.5rem 2rem;
    color: white;
    border-radius: 5px;
    border: none;
    ${media.xs`width: 100%;`}
    ${media.md`padding: 0.7rem 3rem;`}
`

const LogoWrapper = styled.div`
    background: black;
    border-radius: 10%;
    padding:0.5rem 1rem;
`

const Text = styled.div`
   color: black;
   font-weight: 700;
   font-size: 24px;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
    gap:0.6rem;
`;

const AuthWrapper = styled.div`
    display: none;
    ${media.md`display: block;`}
`;

const ResponsiveBar = styled.div`
    position: relative;
    ${media.md`display: none;`}
`;

const Bar = styled.img`
    cursor:pointer;
`;

const DropDown = styled.div`
    width: 200px;
    display: ${({ active }) => active ? 'block' : 'none'};
    position: absolute;
    z-index: 10;
    top: 2rem;
    right: 0;
    overflow: hidden;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    background: white;
`;

const DropList = styled(Link)`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.429;
    color: rgb(9, 44, 76) !important;
    font-weight: bold;
    font-size: 16px;
    padding: 1rem;
    &:hover{
        background: rgb(9, 44, 76);
        cursor:pointer;
        color: #fff !important;
    }
    &:not(:last-child){
        border-bottom: 1px solid #eee;
    }
`;
export default Navbar