import React from 'react'
import {Link} from 'react-router-dom'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'

const Footer = () => {
	return (
		<FooterWraper>
			<Card>
				<Image src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMTIgNWExIDEgMCAxIDEgMC0yIDEgMSAwIDAgMSAwIDJ6bS00IDUuNTkzYTIuNTkzIDIuNTkzIDAgMSAwIDAtNS4xODYgMi41OTMgMi41OTMgMCAwIDAgMCA1LjE4NnpNOCAxMmE0IDQgMCAxIDEgMC04IDQgNCAwIDAgMSAwIDh6TTEuNDgxIDQuNjE3djYuNzY2YTMuMTIgMy4xMiAwIDAgMCAzLjExMSAzLjEyOGg2LjgxNWEzLjEyIDMuMTIgMCAwIDAgMy4xMTEtMy4xMjhWNC42MTdhMy4xMiAzLjEyIDAgMCAwLTMuMTExLTMuMTI4SDQuNTkzYTMuMTIgMy4xMiAwIDAgMC0zLjExMiAzLjEyOHpNMCA0LjYxN0MwIDIuMDY3IDIuMDU2IDAgNC41OTMgMGg2LjgxNUMxMy45NDQgMCAxNiAyLjA2NyAxNiA0LjYxN3Y2Ljc2NkMxNiAxMy45MzMgMTMuOTQ0IDE2IDExLjQwNyAxNkg0LjU5M0MyLjA1NiAxNiAwIDEzLjkzMyAwIDExLjM4M1Y0LjYxN3oiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+PHVzZSB4bGluazpocmVmPSIjYSIvPjwvbWFzaz48dXNlIHhsaW5rOmhyZWY9IiNhIiBmaWxsPSIjRUZFRkYwIi8+PGcgZmlsbD0iI3doaXRlIiBtYXNrPSJ1cmwoI2IpIj48cGF0aCBkPSJNMCAwaDE2djE2SDB6Ii8+PC9nPjwvZz48L3N2Zz4=" alt="" />

				<Image src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTYgMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJtMTIuMzA5IDIuNjU2LTEuNTA2LjAwMWMtMS4xODEgMC0xLjQxLjU2MS0xLjQxIDEuMzg0djEuODE2aDIuODE3bC0uMzY3IDIuODQ1aC0yLjQ1VjE2SDYuNDU2VjguNzAySDRWNS44NTdoMi40NTZWMy43NkM2LjQ1NiAxLjMyNSA3Ljk0MyAwIDEwLjExNCAwYzEuMDQgMCAxLjkzNC4wNzcgMi4xOTUuMTEydjIuNTQ0eiIvPjwvZGVmcz48ZyBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxtYXNrIGlkPSJiIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgeGxpbms6aHJlZj0iI2EiLz48ZyBtYXNrPSJ1cmwoI2IpIj48cGF0aCBkPSJNMCAwaDE2djE2SDB6Ii8+PC9nPjwvZz48L3N2Zz4=" alt="" />

				<Image src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMTQuMzYyIDUuMjM5Yy4wMDYuMTQxLjAxLjI4Mi4wMS40MjUgMCA0LjMzNy0zLjMwMiA5LjMzOS05LjM0IDkuMzM5QTkuMjk0IDkuMjk0IDAgMCAxIDAgMTMuNTI5Yy4yNTcuMDMuNTE4LjA0NS43ODMuMDQ1YTYuNTg0IDYuNTg0IDAgMCAwIDQuMDc3LTEuNDA1QTMuMjg1IDMuMjg1IDAgMCAxIDEuNzkzIDkuODlhMy4zMTIgMy4zMTIgMCAwIDAgMS40ODMtLjA1N0EzLjI4MyAzLjI4MyAwIDAgMSAuNjQzIDYuNjE1di0uMDQyYy40NDIuMjQ2Ljk0OS4zOTQgMS40ODcuNDExYTMuMjgyIDMuMjgyIDAgMCAxLTEuMDE2LTQuMzgzIDkuMzIgOS4zMiAwIDAgMCA2Ljc2NiAzLjQzIDMuMjgzIDMuMjgzIDAgMCAxIDUuNTkzLTIuOTk0IDYuNTY4IDYuNTY4IDAgMCAwIDIuMDg1LS43OTYgMy4yOTkgMy4yOTkgMCAwIDEtMS40NDMgMS44MTZBNi41ODcgNi41ODcgMCAwIDAgMTYgMy41NGE2LjY4MiA2LjY4MiAwIDAgMS0xLjYzOCAxLjY5OXoiLz48L2RlZnM+PGcgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIj48bWFzayBpZD0iYiI+PHVzZSB4bGluazpocmVmPSIjYSIvPjwvbWFzaz48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PGcgbWFzaz0idXJsKCNiKSI+PHBhdGggZD0iTTAgMGgxNnYxNkgweiIvPjwvZz48L2c+PC9zdmc+" alt="" />

				<Image src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMTYgOC4yMzFjMCAxLjI5NS0uMTYgMi41OS0uMTYgMi41OXMtLjE1NiAxLjEwMi0uNjM2IDEuNTg4Yy0uNjA4LjYzNy0xLjI5LjY0LTEuNjAzLjY3Ny0yLjIzOS4xNjItNS42MDEuMTY3LTUuNjAxLjE2N3MtNC4xNi0uMDM4LTUuNDQtLjE2Yy0uMzU2LS4wNjctMS4xNTYtLjA0Ny0xLjc2NC0uNjg0LS40OC0uNDg2LS42MzYtMS41ODgtLjYzNi0xLjU4OFMwIDkuNTI2IDAgOC4yMzFWNy4wMTdjMC0xLjI5NS4xNi0yLjU4OS4xNi0yLjU4OXMuMTU2LTEuMTAzLjYzNi0xLjU4OWMuNjA4LS42MzcgMS4yOS0uNjQgMS42MDMtLjY3N0M0LjYzOCAyIDcuOTk3IDIgNy45OTcgMmguMDA2czMuMzU5IDAgNS41OTguMTYyYy4zMTMuMDM3Ljk5NS4wNCAxLjYwMy42NzcuNDguNDg2LjYzNiAxLjU4OS42MzYgMS41ODlTMTYgNS43MjIgMTYgNy4wMTd2MS4yMTR6bS05Ljk5OSAxLjg5MiA0LjMyMy0yLjI0TDYgNS42MjdsLjAwMSA0LjQ5NnoiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+PHVzZSB4bGluazpocmVmPSIjYSIvPjwvbWFzaz48dXNlIHhsaW5rOmhyZWY9IiNhIiBmaWxsPSIjRUZFRkYwIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48ZyBmaWxsPSIjZmZmIiBtYXNrPSJ1cmwoI2IpIj48cGF0aCBkPSJNMCAwaDE2djE2SDB6Ii8+PC9nPjwvZz48L3N2Zz4=" alt="" />

				<Image src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMy41ODIgMTUuOTg1SC4yNjNWNS4zMTdoMy4zMTl2MTAuNjY4ek0xLjkyNCAzLjg2QTEuOTIzIDEuOTIzIDAgMSAxIDEuOTIxLjAxNWExLjkyMyAxLjkyMyAwIDAgMSAuMDAzIDMuODQ1ek0xNiAxNS45ODVoLTMuMzE1di01LjE4OGMwLTEuMjM3LS4wMjItMi44MjgtMS43MjMtMi44MjgtMS43MjUgMC0xLjk4OCAxLjM0OC0xLjk4OCAyLjc0djUuMjc2SDUuNjYyVjUuMzE3SDguODR2MS40NTloLjA0NmMuNDQyLS44MzkgMS41MjQtMS43MjQgMy4xMzctMS43MjRDMTUuMzggNS4wNTIgMTYgNy4yNjEgMTYgMTAuMTM0djUuODUxeiIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgeGxpbms6aHJlZj0iI2EiIGZpbGw9IiNFRkVGRjAiLz48ZyBmaWxsPSIjd2hpdGUiIG1hc2s9InVybCgjYikiPjxwYXRoIGQ9Ik0wIDBoMTZ2MTZIMHoiLz48L2c+PC9nPjwvc3ZnPg==" alt="" />

				<Image
					src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNOS40MjcgMTEuNTYzYy0uOTkyIDAtMS45MjYtLjUzNi0yLjI0NS0xLjE0NiAwIDAtLjUzNCAyLjExOC0uNjQ2IDIuNTI3LS4zOTggMS40NDQtMS41NjkgMi44ODktMS42NiAzLjAwNy0uMDYzLjA4My0uMjAzLjA1Ny0uMjE4LS4wNTItLjAyNS0uMTg0LS4zMjQtMi4wMDcuMDI4LTMuNDkzbDEuMTgzLTUuMDA4cy0uMjkzLS41ODctLjI5My0xLjQ1NGMwLTEuMzYyLjc4OS0yLjM3OSAxLjc3Mi0yLjM3OS44MzYgMCAxLjIzOS42MjggMS4yMzkgMS4zOCAwIC44NC0uNTM1IDIuMDk3LS44MTEgMy4yNjEtLjIzMS45NzUuNDg5IDEuNzcgMS40NTEgMS43NyAxLjc0IDAgMi45MTMtMi4yMzYgMi45MTMtNC44ODYgMC0yLjAxNC0xLjM1Ni0zLjUyMi0zLjgyNC0zLjUyMi0yLjc4NyAwLTQuNTI1IDIuMDc5LTQuNTI1IDQuNDAyIDAgLjguMjM3IDEuMzY1LjYwNyAxLjgwMi4xNy4yMDEuMTk0LjI4Mi4xMzIuNTEyLS4wNDUuMTctLjE0Ni41NzYtLjE4OS43MzgtLjA2LjIzMy0uMjQ4LjMxNi0uNDU5LjIzQzIuNTk5IDguNzI4IDIgNy4zMjEgMiA1Ljc0MSAyIDMuMTMgNC4yMDEgMCA4LjU2NyAwYzMuNTEgMCA1LjgxOSAyLjUzOCA1LjgxOSA1LjI2NSAwIDMuNjA1LTIuMDA0IDYuMjk4LTQuOTU5IDYuMjk4eiIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgeGxpbms6aHJlZj0iI2EiIGZpbGw9IiNFRkVGRjAiLz48ZyBmaWxsPSIjZmZmIiBtYXNrPSJ1cmwoI2IpIj48cGF0aCBkPSJNMCAwaDE2djE2SDB6Ii8+PC9nPjwvZz48L3N2Zz4="
					alt=""
				/>
			</Card>
		</FooterWraper>
	)
}

const FooterWraper = styled.footer`
	padding: 2rem;
	color: white;
	background: #3b3f44;
	height: auto;
`

const Card = styled.div`
	display: flex;
	justify-content: center;
`
const Image = styled.img`
	height: 1.4rem;
	margin-left: 1.6rem;
	cursor: pointer;
`

export default Footer
