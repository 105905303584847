import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/home/Home'
import "./index.css";
import Business from './pages/business/Business'
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import AdBanner from './components/adBanner';

const App = () => {
	return (
		<div>
			<Navbar />
			<Routes>
				<Route path="/" element={<Home />}></Route>
				<Route path='/business' element={<Business />} />
			</Routes>

			<Footer />
			<AdBanner />
		</div>
	)
}

export default App
