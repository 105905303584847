import React from 'react'
import { Col, Container, Row, media } from 'styled-bootstrap-grid'
import styled from 'styled-components'


const Why = () => {

    const WhyData = [

        {

            "image": "./images/whyimg/1.jpeg",

            "content": "There are over 1.9 million active users searching, booking & buying on the eBookingSystem app",
        },
        {

            "image": "./images/whyimg/2.jpeg",

            "content": "On average, businesses increase client bookings by 26% within 6 months of joining eBookingSystem",
        },
        {
            "image": "./images/whyimg/3.jpeg",

            "content": "To date, over $30 million in funds have been advanced to businesses through eBookingSystem Capital",
        },
        {

            "image": "./images/whyimg/4.jpeg",

            "content": "Over 45,000 studios are powered by eBookingSystem, from local faves to global brands",
        },
        {
            "image": "./images/whyimg/5.jpeg",

            "content": "Last year, over 90 million classes & appointments were booked on the eBookingSystem app",
        },
        {

            "image": "./images/whyimg/6.jpeg",

            "content": "The eBookingSystem app is downloaded six times every minute",
        }

    ]

    return (

        <div>


            <WhyContainer fluid>

                <Heading>Why eBookingSystem?</Heading>

                <Row>

                    {
                        WhyData?.map((elm, index) => {

                            return (

                                <Card xl={4} lg="6" sm="12" key={index}>

                                    <Image src={elm?.image} alt="why"></Image>

                                    <CardBody>{elm?.content}</CardBody>

                                </Card>
                            )
                        })
                    }

                </Row>

            </WhyContainer>
        </div>
    )
}

const WhyContainer = styled(Container)`
padding: 0 2rem;
margin-top: 2rem;
${media.md`padding: 0 3rem;`}

`
const Heading = styled.h2`
font-size: 2.2rem;
text-align:center;
font-weight: bold;`

const Card = styled(Col)`
margin-top: 2rem;

`
const Image = styled.img`
width: 100%;
`
const CardBody = styled.p`
color:#3B3F45;
font-size: 19px;
margin-top: 2rem;
`

export default Why