/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * AdBanner Component
 * Description: This component displays a banner ad using Google AdSense.
 *
 */

import React, { useEffect } from 'react';

const AdBanner = () => {
    useEffect(() => {
        // Trigger AdSense to load ads
        if (window != undefined) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
    }, []);

    return (
        <div>
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-2822975920383791"
                data-ad-slot="9883114865"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
        </div>
    );
};

export default AdBanner;
