import React, { useEffect, useState } from 'react'
import { Col, Container, Row, media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import './form.css'
import axios from 'axios'
import { BiCheck } from 'react-icons/bi'
import Loader from '../Loader'
import { toast } from 'react-toastify'

const Form = () => {
	const [formData, setFormData] = useState('')
	const [loading, setLoading] = useState(false)

	const formSubmit = (e) => {

		e.preventDefault()

		const inputData = new FormData(e.currentTarget)

		const data = {
			fName: inputData.get('fname'),
			businessName: inputData.get('businessname'),
			email: inputData.get('email'),
			businessPhone: inputData.get('phone')
		}

		const { fName, businessName, email, businessPhone } = data

		const sendFormDataToBackend = async () => {
			setLoading(true)
			try {
				const send = await axios.post('https://api.economicsale.finance/booking/users/contact-us', { name: fName, businessName, email, contactInfo: businessPhone })

				if (send.data) {
					if (e?.target) {
						e.target.reset();
					}
					toast.success(`Thank you! We have received your message. We will get back to you shortly.`);

					setLoading(false)

				} else {
					setLoading(false)
					toast.error('Failed to send message. Please try again later.');
				}
			} catch (error) {
				setLoading(false)
				toast.error('Failed to send message. Please try again later.');
				return error
			}
		}

		sendFormDataToBackend()

		setFormData({ fName, businessName, email, businessPhone })
	}

	return (
		<FormWraper id="contactus">
			<Overlay />
			<Container>
				{loading && <Loader />}
				<Row>
					<Col xl={6} lg="12" sm="12" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Heading>Unlock Your Business Potential with eBookingSystem: Elevate Your Standards</Heading>
						<Text>Schedule a demo of our comprehensive software suite. During a 45-minute call, we'll guide you through:</Text>
						<Flex>
							<div>
								<RoundTick>
									<BiCheck />
								</RoundTick>
							</div>
							<Para>Exploring the feature set</Para>
						</Flex>
						<Flex>
							<div>
								<RoundTick>
									<BiCheck />
								</RoundTick>
							</div>
							<Para>Conducting a code walkthrough</Para>
						</Flex>
						<Flex>
							<div>
								<RoundTick>
									<BiCheck />
								</RoundTick>
							</div>
							<Para>Understanding deployment procedures </Para>
						</Flex>
					</Col>
					<Col xl={6} lg="12" sm="12">
						<form onSubmit={formSubmit} style={{ marginTop: '2rem' }}>
							<Container style={{ padding: '0', background: 'white', padding: '2rem', borderRadius: '5px' }}>
								<Row>
									<Col>
										<InputBox>
											<Label>Your Name</Label>
											<Input type="text" name="fname" />
										</InputBox>
										{formData?.fName === '' ? <Error>Your name is required</Error> : ''}
									</Col>
									{/* <Col lg="6">
										<InputBox>
											<Label>Last name</Label>
											<Input type="text" name="lname" />
										</InputBox>
										{formData?.lName === '' ? <Error>this field is required</Error> : ''}
									</Col> */}
								</Row>
								<Row>
									<Col>
										<InputBox>
											<Label>Business Name</Label>
											<Input type="text" name="businessname" />
										</InputBox>
										{formData?.businessName === '' ? <Error>Business name is required</Error> : ''}
									</Col>
								</Row>
								<Row>
									<Col>
										<InputBox>
											<Label>Email</Label>
											<Input type="email" name="email" placeholder="email@yourdomain.com" />
										</InputBox>
										{formData?.email === '' ? <Error>Must be valid email</Error> : ''}
									</Col>
								</Row>
								<Row>
									<Col lg={12}>
										<InputBox>
											<Label>Contact No</Label>
											<Input type="tel" name="phone" />
										</InputBox>
									</Col>
								</Row>

								<Button>LET'S TALK</Button>
							</Container>
						</form>
					</Col>
				</Row>
			</Container>
		</FormWraper>
	)
}

const Flex = styled.div`
	display: flex;
	gap: 1rem;
	margin-top: 1rem;
`

const FormWraper = styled.div`
	padding: 1rem;
	background-image: url('./images/app_cover_2.png');
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: #2d2d2d;
	${media.md` padding: 4rem;`}
`

const Heading = styled.h2`
	font-weight: bold;
	line-height: 1.4;
	color: white;
	font-size: 35px;
	margin-top: 1rem;
	font-family: Averta, Helvetica, Arial, sans-serif;
`

const Text = styled.p`
	color: white;
	font-size: 1.4rem;
	margin-top: 1rem;
`
const Para = styled.p`
	color: white;
	font-size: 1.4rem;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 0;
`
const RoundTick = styled.div`
	height: 15px;
	width: 15px;
	background: white;
	border-radius: 50%;
	margin-top: 0.6rem;
	display: flex;
	justify-content: center;
	align-items: center;
`
const InputBox = styled.div`
	margin-top: 1rem;
`
// const Radio = styled.div`
// 	display: flex;
// 	gap: 0.5rem;
// `
// const RadioSpan = styled.label`
// 	display: block;
// 	font-size: 14px;
// 	font-weight: 400;
// 	margin-top: 0;
// 	margin-bottom: 0;
// 	cursor: pointer;
// `
const Label = styled.p`
	display: block;
	font-size: 14px;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 0;
	color: #2d2d2d;
`
const Error = styled.p`
	display: block;
	color: #e75814;
	font-size: 12px;
`
const Input = styled.input`
	border: 1px solid #2d2d2d;
	border-radius: 3px;
	color: #3b3f45;
	min-height: 48px;
	padding: 8px 16px;
	border: 1px solid #2d2d2d;
	width: 100%;
`

// const Select = styled.select`
// 	border-radius: 3px;
// 	color: #3b3f45;
// 	min-height: 48px;
// 	padding: 8px 16px;
// 	border: 1px solid #2d2d2d;
// 	width: 100%;
// `

const Button = styled.button`
	padding: 12px 16px;
	border: none;
	background: rgb(9, 44, 76);
	width: 100%;
	margin-top: 1rem;
	color: white;
	border-radius: 5px;
`

const Overlay = styled.div`
	position: absolute;
	background: #082c4ccc;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`

export default Form
