import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const Loader = ({ visible, isPopup }) => {
	return (
		<Wrapper visible={visible}>
			<Img className="loading_logo_animation" src={'./images/Logo.png'} alt="logo.svg" right={isPopup} />
		</Wrapper>
	);
};

const Wrapper = styled.div`
    display: ${({ visible }) => (visible === true ? 'none ' : 'flex')};
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background-color: #00000080;
    opacity: 0.92;
    z-index: 15;
    ${media.xs`
	width: 100%;
	height: 100%;
	`}
    ${media.sm`
	width: 100%;
	height: 100%;
	`}
	${media.md`
	width: 100%;
	height: 100%;
	`}
	${media.lg`
	width: 100vw;
	`}
	${media.xl`
	width: 100vw;
	`}
	${media.xxl`
	width: 100vw;
	`}
`;

const Img = styled.img`
    width: 70px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${({ right }) => (right ? '42rem !important' : '0')};
    left: 0;
    margin: auto;
    ${media.xs`
	position: relative;
	`}
    ${media.sm`
	position: relative;
	`}
	${media.md`
	position: absolute;
	`}
	${media.lg`
	position: absolute;
	`}
	${media.xl`
	position: absolute;
	`}
	${media.xxl`
	position: absolute;
	`}
`;
export default Loader;